import { store } from '@/store/store';
import { campos_personalizado } from '@/shared/dtos/campos_personalizado';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { campos_personalizado_nueva_medicion } from '@/shared/dtos/campos_personalizado_nueva_medicion';

@Module({
    namespaced: true,
    name: 'campos_personalizadoModule',
    store,
    dynamic: true
})
class campos_personalizadoModule extends VuexModule {
    public campos_personalizados: campos_personalizado[] = [];
    public tipo_activado: boolean = false;
    public campos_personalizado: campos_personalizado = new campos_personalizado();
    public campos_personalizado_nueva_medi: campos_personalizado_nueva_medicion[] = [];

    @Action({ commit: 'onGetcampos_personalizados' })
    public async getcampos_personalizados() {
        return await ssmHttpService.get(API.campos_personalizados);
    }

    @Action({ commit: 'onGetcampos_personalizado' })
    public async getcampos_personalizado(id: number) {
        return await ssmHttpService.get(API.campos_personalizados + '/' + id);
    }
    @Action({ commit: 'onGetTipoActivado' })
    public async getTipoActivado(id: number) {
        return await ssmHttpService.get(API.campos_personalizados + '/tipo_activado/' + id);
    }
    @Action({ commit: 'onGetcampos_personalizados' })
    public async getcampos_personalizado_por_tipo(id: number) {
        return await ssmHttpService.get(API.campos_personalizados + '/portipo/' + id);
    }
    @Action({ commit: 'onGetcampos_personalizados_nueva_medicion' })
    public async getNuevaMedicionCampos() {
        return await ssmHttpService.get(API.campos_personalizados + '/nueva_medicion/');
    }
    @Action({ commit: 'onGetcampos_personalizados_nueva_medicion' })
    public async getMedicionCampos(id_cab: number) {
        return await ssmHttpService.get(API.campos_personalizados + '/medicion/' + id_cab);
    }

    @Action({ commit: 'onGetcampos_personalizados' })
    public async crearCamposParaEltipoIId_nutri(id: number) {
        return await ssmHttpService.post(API.campos_personalizados + '/crearcamposparanutri/' + id, null);
    }
    @Action({ commit: 'onGetcampos_personalizados' })
    public async eliminarCamposParaEltipoIId_nutri(id: number) {
        return await ssmHttpService.delete(API.campos_personalizados + '/eliminarcamposparanutri/' + id, null, false);
    }
    @Action
    public async guardarcampos_personalizado(campos_personalizado: campos_personalizado) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.campos_personalizados, campos_personalizado.toJson());
    }

    @Action
    public async guardarnueva_medicion({ campos_personalizado_nueva, id_paciente }: any) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.campos_personalizados + '/guardar_nueva_medicion/' + id_paciente, JSON.stringify(campos_personalizado_nueva));
    }
    @Action
    public async actualizar_medicion({ campos_personalizado_nueva, id_paciente }: any) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.campos_personalizados + '/actualizarmedicion/' + id_paciente, JSON.stringify(campos_personalizado_nueva));
    }

    @Action
    public async modificarcampos_personalizado(campos_personalizado: campos_personalizado) {
        return await ssmHttpService.put(API.campos_personalizados + '/' + campos_personalizado.id, campos_personalizado);
    }

    @Action
    public async eliminarcampos_personalizado(id: number) {
        await ssmHttpService.delete(API.campos_personalizados + '/' + id, null, false);
    }

    @Mutation
    public onGetcampos_personalizados(res: campos_personalizado[]) {
        this.campos_personalizados = res ? res.map((x) => new campos_personalizado(x)) : [];
    }


    @Mutation
    public onGetcampos_personalizados_nueva_medicion(res: campos_personalizado_nueva_medicion[]) {
        this.campos_personalizado_nueva_medi = res ? res.map((x) => new campos_personalizado_nueva_medicion(x)) : [];
    }

    @Mutation
    public onGetcampos_personalizado(res: campos_personalizado) {
        this.campos_personalizado = new campos_personalizado(res);
    }
    @Mutation
    public onGetTipoActivado(res: boolean) {
        this.tipo_activado = res;
    }

}
export default getModule(campos_personalizadoModule);