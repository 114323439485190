import { BaseDto } from '@/shared/dtos/base-dto';

export class campos_personalizado extends BaseDto {
    public nombre !: string;
    public formato_medicion !: string;
    public visible_en_estadicticas !: boolean;
    public sistema !: boolean;
    public id_nutricionista !: number;
    public id_tipos_campos_personalizados !: number;

} 
